import React from 'react';
import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal
} from './modal';
import { StyledExtraModal } from './MessageModal';
import styled from 'styled-components';
import EndGameStatsModal from './EndGameStatsModal';

const SuccessModalBackground = styled(ModalBackground)`
  background-image: url(${process.env.PUBLIC_URL}/game_success_map.png);
  background-size: contain;
`;

export default () => {
  return <SuccessModalBackground>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Bienvenue encore une fois sur Sagittaire!
        </Header>
        <Body>
          <p>Nous sommes très heureux de pouvoir entendre le récit de votre mission réussie!</p>
          <p>Lorsque vous serez prêt, appelez le commandant spatial pour qu’il vienne vous féliciter personnellement!</p>
          <p>Merci de tout ce que vous avez fait pour sauver Orion!</p>
          <p>-Poste de commande au sol </p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>      
    </BaseModalBackground>
  </SuccessModalBackground>;
}