import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Mission abandonnée!
        </Header>
        <Body>
          <p>Vous avez abandonné votre mission. Sans votre aide, nous craignons que le pire soit arrivé à la station spatiale Orion. </p>
          <p>Alertez le commandant spatial et demandez-lui de venir pour vous dire ce que vous devez faire maintenant. </p>
          <p>-Poste de commande au sol </p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}