import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal,
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Retransmission provenant du poste de commande au sol
        </Header>
        <Body>
          <p>Oh non! Il semble que vous soyez en retard.</p>
          <p>Les systèmes d’oxygène n’ont pas été réparés de manière permanente à temps, et le pire est arrivé. Tous les scientifiques de la station spatiale Orion sont décédés – ils ont pris leur place dans le firmament des étoiles!</p>
          <p>Bien que nous n’ayons pas réussi notre mission, discutons-en pour comprendre où nous nous sommes trompés. Qu’aurions-nous pu faire différemment?</p>
          <p>-Poste de commande au sol</p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}