import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal,
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Attention, équipage de Gemini. 
        </Header>
        <Body>
          <p>Le temps dont vous disposiez pour le sauvetage d’Orion est malheureusement écoulé. </p>
          <p>Notre équipe de commandement poursuivra le travail de sauvetage de la station spatiale, mais vous devez revenir au centre de commande. </p>
          <p>Bouclez votre ceinture; nous sommes sur le point de vous téléporter pour rencontrer le commandant spatial afin de faire un compte rendu de votre mission. </p>
          <p>-Poste de commande au sol </p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}