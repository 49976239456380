import React from 'react';
import EndGameStatsModal from './EndGameStatsModal';

import {
  BaseModalBackground,
  ModalBackground,
  Header,
  Body,
  StyledModal,
} from './modal';
import { StyledExtraModal } from './MessageModal';

export default () => {
  return <ModalBackground zIndex={1}>
    <BaseModalBackground>
      <StyledModal>
        <Header>
          Retransmission provenant du poste de commande au sol
        </Header>
        <Body>
          <p>Oh non! Votre vaisseau a épuisé les resources nécessaires pour maintenir votre navire en mouvement afin que vous puissiez mener à bien votre mission!</p>
          <p>Veuillez envoyer un appel de détresse au commandant spatial et nous vous fournirons des instructions supplémentaires.</p>
          <p>-Poste de commande au sol</p>
        </Body>
      </StyledModal>
      <StyledExtraModal>
        <EndGameStatsModal />
      </StyledExtraModal>    
    </BaseModalBackground>
  </ModalBackground>
}