import { SpaceStationMetadata } from './types';

const metadata: SpaceStationMetadata = {
  'andromède': {
    location: 'andromède',
    message: {
      asset: 'modals/Andromeda_Information_Card.jpg',
      note: "DES RESSOURCES DE SAUVETAGE SONT DISPONIBLES DANS CETTE STATION – ELLES PEUVENT ÊTRE RÉCUPÉRÉES À L’AIDE DE STARWAY",
      technology: "Équipe de restauration alimentaire",
      title: 'Équipe de restauration alimentaire',
      paragraphs:[
        {text: 'L’équipe de restauration alimentaire est actuellement en mission. Elle ne reviendra pas sur Andromède avant le Jour 14.'}
      ],
      sideNote: "disponible le Jour 14",
    }
  },
  'verseau': {
    location: 't3',
    message: {
      asset: 'modals/Aquarius_Information_Card.jpg',
      note: "DES RESSOURCES DE SAUVETAGE SONT DISPONIBLES DANS CETTE STATION – ELLES PEUVENT ÊTRE RÉCUPÉRÉES À L’AIDE DE STARWAY",
      technology: "Équipe de restauration de l’eau",
      title: 'Nourriture sur Orion',
      paragraphs: [
        {text: 'Les systèmes de transformation des aliments ont été détruits.'},
        {text: 'Les réserves alimentaires d’urgence s’épuisent et des personnes commenceront à mourir au Jour 24, à moins que les systèmes soient réparés.'},
        {text: 'Une équipe de restauration alimentaire deviendra disponible sur Andromède.'},
      ],
    }
  },
  'borealis': {
    location: 't5',
    message: {
      asset: 'modals/Borealis_Information_Card.jpg',
      note: "DES RESSOURCES DE SAUVETAGE SONT DISPONIBLES DANS CETTE STATION – ELLES PEUVENT ÊTRE RÉCUPÉRÉES À L’AIDE DE STARWAY",
      technology: "Équipe de restauration de l’oxygène",
      title: 'Restauration de l’O2 sur Orion',
      paragraphs: [
        {text: 'L’équipe de restauration de l’O2 peut rétablir immédiatement l’O2 sur ORION dès son arrivée sur place. Cette restauration sera définitive.'},
        {text: 'Elle ne peut toutefois quitter Borealis avant que la technologie d’IA de Capricorne vienne la remplacer.'},
        {text: 'Si l’équipe de restauration de l’O2 quitte Borealis avant ce moment, elle mettra la vie de 200 scientifiques de cette station en danger.'},
      ],
    }
  },
  'capricorne': {
    location: 't4',
    message: {
      asset: 'modals/Capricorn_Information_Card.jpg',
      note: "DES RESSOURCES DE SAUVETAGE SONT DISPONIBLES DANS CETTE STATION – ELLES PEUVENT ÊTRE RÉCUPÉRÉES À L’AIDE DE STARWAY",
      technology: "Technologie d’IA",
      title: 'Blessures sur Orion',
      paragraphs: [
        {text: 'Trois personnes ont subi des blessures lorsque les dommages se sont produits.'},
        {text: 'Les blessures s’aggravent progressivement, et les trois personnes mourront au Jour 25 si aucun traitement médical spécialisé n’est fourni.'},
        {text: 'L’équipe médicale est actuellement disponible sur Cassiopée.'},
      ]
    }
  },
  'cassiopée': {
    location: 'cassiopée',
    message: {
      asset: 'modals/Cassiopeia_Information_Card.jpg',
      note: "DES RESSOURCES DE SAUVETAGE SONT DISPONIBLES DANS CETTE STATION – ELLES PEUVENT ÊTRE RÉCUPÉRÉES À L’AIDE DE STARWAY",
      technology: "Équipe médicale",
      title: 'Approvisionnement d’eau sur Orion',
      paragraphs: [
        {text: 'Le système de traitement de l’eau s’est arrêté lorsque les dommages sont survenus.'},
        {text: 'Il y a suffisamment d’eau en réserve pour garder les scientifiques en vie jusqu’au Jour 23, au plus tard. Des pertes de vies sont à prévoir, à moins que les systèmes de traitement de l’eau de la station soient définitivement réparés.'},
        {text: 'Une équipe de réparation des systèmes de traitement de l’eau est disponible sur Aquarius.'},
      ]
    }
  },
  'orion': {
    location: 't2',
    message: {
      asset: 'modals/Orion_Information_Card.jpg',
      title: 'O2 sur Orion',
      paragraphs: [
        {text: 'Les provisions d’oxygène de la station spatiale Orion s’épuisent rapidement, et les scientifiques risquent d’en manquer.'},
        {text: 'Les cellules de remplacement d’O2 qui sont à bord de votre vaisseau représentent une solution temporaire au problème de génération d’oxygène. Cette solution est valable jusqu’au Jour 21.'},
        {text: 'Des décès se produiront si aucune solution permanente pour la génération d’oxygène n’est mise en place avant le Jour 21.'},
      ]
    }
  },
  'sagittaire': {
    location: 'sagittaire',
    message: {
      asset: 'modals/Sagittarius_Information_Card.jpg',
      title: 'Dommages subis par la station spatiale Orion',
      paragraphs: [
        {text: 'La principale source de génération d’oxygène d’Orion a été gravement touchée.'},
        {text: 'La station n’a des réserves d’oxygène que pour 6 jours avant que des décès surviennent.'},
        {text: 'Sans réparations, la production d’oxygène s’arrêtera complètement dans 21 jours.'},
      ]
    }
  },
};

export default metadata;
